import { cloneDeep, compact, find } from 'lodash';
import { allConditionsById, copyCondition } from '@/common';

const ConditionsMixin = {
    methods: {
        hasMeta(condition, metaProp) {
            return condition.meta && condition.meta.hasOwnProperty(metaProp); // eslint-disable-line no-prototype-builtins
        },
        hasSnomed(condition) {
            const _condition = allConditionsById[condition.id];
            return _condition?.snomed && compact(_condition?.snomed).length;
        },
        infoLink(condition) {
            const _condition = allConditionsById[condition.id];
            return _condition?.attr?.infoLink;
        },
        metaProperties(condition) {
            return Object.keys(condition.meta || {}).filter((key) => {
                if (key === 'numberOfSkinCancers') {
                    return false; // deprecated
                }
                if (key === 'a1c') {
                    return false; // deprecated
                }
                if (key === 'a1cTestYear') {
                    return false; // deprecated
                }
                if (key === 'numberOfSkinCancersMelanoma') {
                    return this.isCancerTypeSelected(this.condition, 'melanoma');
                }
                if (key === 'numberOfSkinCancersNonMelanoma') {
                    return this.isCancerTypeSelected(this.condition, 'non_melanoma');
                }
                return true;
            });
        },
        isCancerTypeSelected(condition, type) {
            if (condition && condition.meta && condition.meta.cancerTypes) {
                for (const cancerType of condition.meta.cancerTypes) {
                    if (cancerType.id === type && cancerType.selected) {
                        return true;
                    }
                }
            }
            return false;
        },
        valueLabel(value) {
            switch (value) {
                case 'Y':
                    return this.$i18n.t('common.yes');
                case 'N':
                    return this.$i18n.t('common.no');
                case 'none':
                    return this.$i18n.t('common.none');
                case 'unknown':
                    return this.$i18n.t('common.unknown');
            }
            return value;
        },
        valueUnitLabel(obj) {
            if (obj && obj.value) {
                if (obj.unit) {
                    return `${obj.value} ${this.$i18n.t(`units.${obj.unit}`)}`;
                }
                return obj.value;
            }
            return null;
        },
        categoryIcon(category) {
            switch (category) {
                case 'blood':
                    return 'fa-vial';
                case 'brain':
                    return 'fa-brain';
                case 'cancer':
                    return 'fa-ribbon';
                case 'cardiovascular':
                    return 'fa-heartbeat';
                case 'circulatory':
                    return 'fa-sync-alt';
                case 'common':
                    return 'fa-plus';
                case 'digestive':
                    return 'fa-stomach';
                case 'eye':
                    return 'fa-eye';
                case 'female':
                    return 'fa-female';
                case 'hereditary':
                    return 'fa-dna';
                case 'kidney':
                    return 'fa-kidneys';
                case 'liver':
                    return null;
                case 'lung':
                    return 'fa-lungs';
                case 'musculoskeletal':
                    return 'fa-bone';
                case 'psychological':
                    return 'fa-head-side-brain';
                case 'skin':
                    return 'fa-allergies';
                case 'thyroid':
                    return null;
            }
            return null;
        },
        copy(condition) {
            return copyCondition(condition);
        },
        add(person, condition, property = 'conditions', cancelFn = () => {}) {
            condition = this.copy(condition);
            if (condition.skipQuestions) {
                this.$store.commit('addFamilyMemberCondition', { person, condition, property });
            } else {
                this.$root.$conditionDialog
                    .add(person, condition)
                    .then(({ condition, causeOfDeath }) => {
                        this.$store.commit('addFamilyMemberCondition', { person, condition, causeOfDeath, property });
                    })
                    .catch(cancelFn);
            }
        },
        update(person, condition, property = 'conditions', cancelFn = () => {}) {
            if (condition.skipQuestions) {
                return;
            }
            this.$root.$conditionDialog
                .update(person, cloneDeep(condition))
                .then(({ condition, causeOfDeath }) => {
                    this.$store.commit('updateFamilyMemberCondition', { person, condition, causeOfDeath, property });
                })
                .catch(cancelFn);
        },
        remove(person, condition, property = 'conditions', cancelFn = () => {}) {
            this.$root.$confirmationDialog
                .show({ title: this.$i18n.t('prompts.removeCondition.title'), message: this.$i18n.t('prompts.removeCondition.message') })
                .then(() => {
                    this.$store.commit('removeFamilyMemberCondition', { person, condition, property });
                })
                .catch(cancelFn);
        },
        has(person, condition, property = 'conditions') {
            return !!find(person[property], { id: condition.id });
        },
        findCondition(person, condition, property = 'conditions') {
            return find(person[property], { id: condition.id });
        },
        canAdd(person, condition, property = 'conditions') {
            return /^other/.test(condition.id) || !this.has(person, condition, property);
        },
        canRemove(person, condition, property = 'conditions') {
            return !/^other/.test(condition.id) && this.has(person, condition, property);
        }
    }
};

export { ConditionsMixin };
